.raoSahabni {
	width: 1000px;
  }
  
  .image-container {
	width: calc(100% / 6); /* Adjust the number '6' according to the number of images you want to display */
	float: left;
	padding: 10px;
	box-sizing: border-box;
  }
  .image-slider-item {
	width: 106px;
	height: 103px;
  }