.rotating-background {
	position: absolute;
	width: 100%;
	height: 100%;
	background: url(https://drive.google.com/uc?export=view&id=1EmJ6pv2tSP2sVGyGNjRPZlDVma2F0w6L);
	background-position: center;
	background-repeat: no-repeat;
	animation: rotateBackground 10s linear infinite;
  }
  
  .content-container {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  